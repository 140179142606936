import classNames from 'classnames';
import { FC } from 'react';

import { Icon, toIconName } from '@lichtblick/icons';
import { Text, TextSizeClass } from '@lichtblick/photon/atoms/text/text';

import styles from './a-list-item.module.scss';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { AListItemType } from '../../types/storyblok';
import { ColorKey } from '../../utils';

export type AListItemProps = AListItemType & {
  className?: string;
  colorOverride?: ColorKey;
  textSizeOverride?: Extract<TextSizeClass, 'm' | 's'>;
};

const iconColorMap: Record<Exclude<AListItemType['color'], undefined | ''>, ColorKey> = {
  red: 'light-red',
  green: 'light-moss',
  black: 'black',
};

const getElementMappers = (textSizeOverride: Extract<TextSizeClass, 'm' | 's'>): Partial<ElementMappers> => ({
  p: ({ children }) => (
    <Text renderAs="p" size={textSizeOverride}>
      {children}
    </Text>
  ),
});

export const AListItem: FC<AListItemProps> = ({
  className,
  color,
  colorOverride,
  icon,
  isBold,
  richText,
  textSizeOverride = 'm',
  title,
  value,
}) => {
  const content = richText && <RichText elementMappers={getElementMappers(textSizeOverride)} richText={richText} />;

  if (!content) {
    return null;
  }

  return title && value ? (
    <li className={styles['paired-item']} key={value}>
      <Text bold={isBold}>{title}</Text>
      <span className={styles['paired-item-dotted-line']} />
      <Text bold={isBold}>{value}</Text>
    </li>
  ) : (
    <li className={classNames(styles.item, className)}>
      {icon && (
        <Icon
          className={classNames(
            styles.icon,
            textSizeOverride === 's' && styles['icon-small-margin-top'],
            styles[`icon-color-${colorOverride || iconColorMap[color || 'green']}`],
          )}
          name={toIconName(icon)}
        />
      )}
      <Text size={textSizeOverride}>{content}</Text>
    </li>
  );
};
